import React, {useEffect, useRef, useState} from 'react';
import {Alert, AlertTitle, Button, CircularProgress, Grid, Snackbar, Typography} from '@mui/material';
import config from '../config';
import './css/Training.css';
import {getHeaders} from "../utils";

interface ModelData {
    name: string;
    status: string;
    customizationId: string,
    baseModelName: string
    // Add any other properties here
}

export const Training = () => {
    const [isLanguageSubmitting, setIsLanguageSubmitting] = useState(false);
    const [isLanguageStatusLoading, setIsLanguageStatusLoading] = useState(false);
    const [isAcousticSubmitting, setIsAcousticSubmitting] = useState(false);
    const [isAcousticStatusLoading, setIsAcousticStatusLoading] = useState(false);
    const [languageModelData, setLanguageModelData] = useState<ModelData | null>(null
    );
    const [acousticModelData, setAcousticModelData] = useState<ModelData | null>(
        null,
    );
    const [languageModelError, setLanguageModelError] = useState<string>('');
    const [acousticModelError, setAcousticModelError] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const POOLING_INTERVAL = 3000000;


    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        getStatusLanguageModel();
        getStatusAcousticModel();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current!);
        };
    }, []);

    const handleDismiss = (errorType: 'languageModelError' | 'acousticModelError') => {
        if (errorType === 'languageModelError') {
            setLanguageModelError('');
        } else if (errorType === 'acousticModelError') {
            setAcousticModelError('');
        }
    };

    const trainLanguageModel = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLanguageSubmitting(true);
        setLanguageModelError('');

        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/train`, {
                method: 'POST',
                body: JSON.stringify({
                    customizationId: languageModelData?.customizationId,
                }),
                headers: getHeaders(),

            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            getStatusLanguageModel();
        } catch (err: any) {
            setLanguageModelError(`Error initializing the training: ${err.message}`);
        } finally {
            setIsLanguageSubmitting(false);
        }
    };

    const trainAcousticModel = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsAcousticSubmitting(true);
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/train-acoustic`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    customizationId: acousticModelData?.customizationId,
                    customLanguageModelId: languageModelData?.customizationId
                }),

            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            getStatusAcousticModel();
        } catch (err: any) {
            setAcousticModelError(`Error initializing the training: ${err.message}`);
        } finally {
            setIsAcousticSubmitting(false);
        }
    };

    const checkModelStatusDone = (status: string) => {
        const nonPollStatuses = ['ready', 'available', 'failed'];
        return nonPollStatuses.includes(status);
    };

    const checkModelTrainable = (data: ModelData | null) => {
        if (!data) {
            return false;
        } else if (['ready', 'failed'].includes(data.status)) {
            return true;
        }
        return false;
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'ready':
                return 'text-info';
            case 'available':
                return 'text-success';
            case 'training':
                return 'text-warning';
            case 'failed':
                return 'text-danger';
            default:
                return 'text-secondary';
        }
    };

    const pollLanguageModelStatus = () => {
        getStatusLanguageModel(true);
    };

    const pollAcousticModelStatus = () => {
        getStatusAcousticModel(true);
    };

    const getStatusLanguageModel = async (poll = false) => {
        if (!poll) setIsLanguageStatusLoading(true);

        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setLanguageModelData(data?.data);
            const isNotActive = checkModelStatusDone(data.data.status);

            if (isNotActive && poll) {
                clearInterval(intervalRef.current!);
            } else if (!isNotActive && !poll) {
                intervalRef.current = setInterval(pollLanguageModelStatus, POOLING_INTERVAL);
            }

            if (!poll) setIsLanguageStatusLoading(false);
        } catch (err: any) {
            setLanguageModelError(`Error getting language model data: ${err.message}`);
            if (!poll) setIsLanguageStatusLoading(false);
        }
    };

    const getStatusAcousticModel = async (poll = false) => {
        if (!poll) setIsAcousticStatusLoading(true);
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/acoustic-model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setAcousticModelData(data?.data);
            const isNotActive = checkModelStatusDone(data.data.status);

            if (isNotActive && poll) {
                clearInterval(intervalRef.current!);
            } else if (!isNotActive && !poll) {
                intervalRef.current = setInterval(pollAcousticModelStatus, POOLING_INTERVAL);
            }

            if (!poll) setIsAcousticStatusLoading(false);
        } catch (err: any) {
            setAcousticModelError(`Error getting acoustic model data: ${err.message}`);
            if (!poll) setIsAcousticStatusLoading(false);
        }
    };

    return (
        <div className='Train'>
            <div style={{width: '70%'}}>
                <Typography variant='h4'>Train Custom Models</Typography>
                <p>
                    If you have recently added language or audio resources, the model needs to be trained to account for
                    the
                    new data. Kick off a training session here. If a model's status is <code>ready</code>, then this
                    indicates that the model contains data and is ready to be trained. A status
                    of <code>available</code> indicates that the model is trained and ready to use.
                </p>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant='h6'>Language Model Status</Typography>
                        <div className='status-box'>
                            {isLanguageStatusLoading && <CircularProgress className='loadingstatus'/>}
                            {languageModelData && !isLanguageStatusLoading && (
                                <div className='modelstatus'>
                                    <strong>Name:</strong> {languageModelData.name}<br/>
                                    <strong>Status:</strong>{' '}
                                    <span className={getStatusColor(languageModelData.status)}>
                {languageModelData.status}{' '}
                                        {languageModelData.status === 'training' && (
                                            <CircularProgress size={20} className='training'/>
                                        )}
              </span>
                                </div>
                            )}
                        </div>

                        {/* LoadButton */}
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={isLanguageStatusLoading || !checkModelTrainable(languageModelData)}
                            onClick={trainLanguageModel}
                        >
                            Train Language Model
                        </Button>
                        {/* AlertDismissible */}
                        <Snackbar open={!!languageModelError} autoHideDuration={6000}
                                  onClose={() => handleDismiss('languageModelError')}>
                            <Alert onClose={() => handleDismiss('languageModelError')} severity='error'>
                                <AlertTitle>Language Model Error</AlertTitle>
                                {languageModelError}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h6'>Acoustic Model Status</Typography>
                        <div className='status-box'>

                            {/* Well */}
                            {isAcousticStatusLoading && <CircularProgress className='loadingstatus'/>}
                            {acousticModelData && !isAcousticStatusLoading && (
                                <div className='modelstatus'>
                                    <strong>Name:</strong> {acousticModelData.name}<br/>
                                    <strong>Status:</strong>{' '}
                                    <span className={getStatusColor(acousticModelData.status)}>
                {acousticModelData.status}{' '}
                                        {acousticModelData.status === 'training' && (
                                            <CircularProgress size={20} className='training'/>
                                        )}
              </span>

                                </div>
                            )}
                        </div>

                        {/* LoadButton */}
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={isAcousticStatusLoading || !checkModelTrainable(acousticModelData)}
                            onClick={trainAcousticModel}
                        >
                            Train Acoustic Model
                        </Button>
                        {/* AlertDismissible */}
                        <Snackbar open={!!acousticModelError} autoHideDuration={6000}
                                  onClose={() => handleDismiss('acousticModelError')}>
                            <Alert onClose={() => handleDismiss('acousticModelError')} severity='error'>
                                <AlertTitle>Acoustic Model Error</AlertTitle>
                                {acousticModelError}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};

