import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import {
    Alert,
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
// @ts-ignore
import {LanguageModel, PrettoSlider, TranscriptionParams} from '../../common';
import CloseIcon from '@mui/icons-material/Close';
import {socket} from "../../socket";

let params: TranscriptionParams = {};


export const VoiceTranscriber = () => {
    const [transcript, setTranscript] = useState('');
    const [speakerViseSummary, setSpeakerViseSummary] = useState('');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [sensitivity, setSensitivity] = useState(0.5); // Default sensitivity value
    const [backgroundAudioSuppression, setBackgroundAudioSuppression] = useState(0.0); // Default sensitivity value
    const [speakerLabels, setSpeakerLabels] = useState('0');
    const [smartFormatting, setSmartFormatting] = useState('0');
    const [languageModel, setLanguageModel] = useState<LanguageModel>('en-US_BroadbandModel');
    const [error, setError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const languageModelOptions: Partial<Record<LanguageModel, string>> = {
        'en-US_BroadbandModel': 'English US - Broadband Model',
        'en-IN_Telephony': 'English India - Telephony',
        'en-US_Telephony': 'English US - Telephony',
        'en-US_Multimedia': 'English US - Multimedia',
        'en-GB_Multimedia': 'English UK - Multimedia',
        'en-US_NarrowbandModel': 'English US NarrowBand Modal',
    };

    useEffect(() => {
        socket.on('transcript-v1', (newTranscript: string) => {
            setTranscript(prevTranscript => prevTranscript + '\n' + newTranscript);
        });

        socket.on('final-transcript-v1', (final: string) => {
            setTranscript(final);
        });

        socket.on('error', (error: string) => {
            stopRecording();
            setError(error);
            setSnackbarOpen(true);

        });

        return () => {
            socket.off('transcript-v1');
            //
        };
    }, []);

    const startRecording = async () => {
        params = {
            backgroundAudioSuppression,
            speechDetectorSensitivity: sensitivity,
            speakerLabels: !!(+speakerLabels),
            model: languageModel,
            smartFormatting: !!(+smartFormatting),
        };
        socket.emit('start-transcription-v1', params);

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
            // const options = {mimeType: 'audio/webm'}; // Use webm for better compatibility
            const options = {}; // Use webm for better compatibility

            const newMediaRecorder = new MediaRecorder(stream, options);
            newMediaRecorder.ondataavailable = async (event) => {
                if (event.data.size > 0) {
                    socket.emit('voice-v1', await event.data.arrayBuffer());
                }
            };
            newMediaRecorder.start(250); // Increased timeslice for reduced network load
            setMediaRecorder(newMediaRecorder);
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setError(null);
    };

    const stopRecording = () => {
        mediaRecorder?.stop();
        mediaRecorder?.stream.getTracks().forEach(track => track.stop());
        setIsRecording(false);
        socket.emit('voice-end-v1');
    };

    const restTranscription = () => {
        setTranscript('');
    };

    const handleSensitivityChange = (event: Event, newValue: number | number[]) => {
        setSensitivity(newValue as number);

    };

    const handleBackgroundAudioSuppressionChange = (event: Event, newValue: number | number[]) => {
        setBackgroundAudioSuppression(newValue as number);

    };

    const handleShowSpeakerLabelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSpeakerLabels((event.target as HTMLInputElement).value);
    };

    const handleSmartFormattingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSmartFormatting((event.target as HTMLInputElement).value);
    };

    const handleLanguageModelChange = (event: SelectChangeEvent) => {
        setLanguageModel(event.target.value as LanguageModel);
    };

    return (
        <Container>
            <Stack mt={3}></Stack>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={10000} // Adjust the duration as needed
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            >
                <Alert
                    severity='error'
                    action={
                        <IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackbarClose}>
                            <CloseIcon fontSize='small'/>
                        </IconButton>
                    }
                >
                    {error}
                </Alert>
            </Snackbar>

            <div className='wrapper' style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '40px',
                marginTop: '40px'
                // minHeight: '100vh',
            }}>
                <Box display='flex' my='4' alignItems='center' justifyContent='center' style={{width: '100%'}}>
                    <Box flex={1} sx={{flexGrow: 1}}>
                        <div className='outputs'>
                            {/*<Typography variant='h5' gutterBottom>Live Transcription:</Typography>*/}
                            <div style={{maxHeight: '300px'}}>
                                <TextField
                                    label='Live Transcription'
                                    multiline
                                    fullWidth
                                    variant='outlined'
                                    rows={12}
                                    value={transcript}
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            minHeight: '200px',
                                            overflowY: 'auto',
                                        },
                                    }}
                                />
                            </div>
                        </div>


                    </Box>
                    <Box flex={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                        <div className='controlls' style={{maxHeight: 'calc(100vh - 100px)', overflowY: 'auto'}}>
                            <div className='actions' style={{display: 'flex', gap: '20px'}}>
                                < Button variant='contained' color='success' onClick={startRecording}
                                         disabled={isRecording}>
                                    Start Recording
                                </Button>
                                <Button variant='contained' color='error' onClick={stopRecording}
                                        disabled={!isRecording}>
                                    Stop Recording
                                </Button>
                                <Button variant='contained' color='warning' onClick={restTranscription}
                                        disabled={isRecording}>
                                    Reset
                                </Button>
                            </div>
                            <Stack mt={3}></Stack>

                            <FormControl fullWidth disabled={isRecording}>
                                <InputLabel id='language-model-select-label'>Language Model</InputLabel>
                                <Select
                                    labelId='language-model-select-label'
                                    id='language-model-select'
                                    value={languageModel}
                                    label='Language Model'
                                    onChange={handleLanguageModelChange}
                                >
                                    {Object.entries(languageModelOptions).map(([modelKey]) => (
                                        <MenuItem key={modelKey} value={modelKey}>
                                            {languageModelOptions[modelKey as LanguageModel]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack mt={2}></Stack>
                            <PrettoSlider
                                valueLabelDisplay='auto'
                                aria-label='speech-sensitivity-slider'
                                value={sensitivity}
                                step={0.1}
                                min={0}
                                max={1}
                                disabled={isRecording}
                                onChange={handleSensitivityChange}
                            />
                            <Typography gutterBottom>Speech Sensitivity</Typography>
                            <PrettoSlider
                                valueLabelDisplay='auto'
                                value={backgroundAudioSuppression}
                                step={0.1}
                                min={0}
                                max={1}
                                disabled={isRecording}
                                onChange={handleBackgroundAudioSuppressionChange}
                            />
                            <Typography gutterBottom>Background audio suppression</Typography>
                            <Stack mt={2}></Stack>
                            <FormControl>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '1em',
                                    }}>
                                    <div id='labels'><strong>Show Speaker Labels?</strong></div>

                                    <RadioGroup aria-labelledby='labels'
                                                row value={speakerLabels} onChange={handleShowSpeakerLabelsChange}>
                                        <FormControlLabel value='1' disabled={isRecording}
                                                          control={<Radio/>} color='secondary' label='Yes'/>
                                        <FormControlLabel value='0' disabled={isRecording}
                                                          control={<Radio/>} color='error' label='No'/>
                                    </RadioGroup>
                                </div>
                                <small>** it's only works with recorded audio files</small>
                            </FormControl>

                            <Stack mt={2}></Stack>
                            <FormControl>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '1em',
                                    }}>
                                    <div id='smart-formatiing'><strong>Smart formatting?</strong></div>
                                    <RadioGroup aria-labelledby='smart-formatiing'
                                                row value={smartFormatting} onChange={handleSmartFormattingChange}>
                                        <FormControlLabel value='1' disabled={isRecording}
                                                          control={<Radio/>} color='secondary' label='Yes'/>
                                        <FormControlLabel value='0' disabled={isRecording}
                                                          control={<Radio/>} color='error' label='No'/>
                                    </RadioGroup>
                                </div>
                            </FormControl>
                        </div>

                    </Box>
                </Box>
            </div>
        </Container>
    )
        ;
};
