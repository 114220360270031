import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';
import {Header, VoiceTranscriber} from './components';
import {Login, Training, Transcribe} from './pages';
import Corpora from "./pages/Corpora";
import Audio from "./pages/Audio";
import Words from "./pages/Words";


const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(','),
    },
});


function App() {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [userData, setUserData] = useState<any>({});

    useEffect(() => {
        // Check if the user is authenticated in local storage
        const isAuthenticated = localStorage.getItem('authenticated') === 'true';
        setAuthenticated(isAuthenticated);

        if (isAuthenticated) {
            // Retrieve user data from local storage
            const userDataFromStorage = localStorage.getItem('user');
            const userData = userDataFromStorage ? JSON.parse(userDataFromStorage) : {};
            setUserData(userData);
        }
    }, []);

    const userHasAuthenticated = (isAuthenticated: boolean, userData: any = {}) => {
        setAuthenticated(isAuthenticated);

        if (isAuthenticated) {
            // Store authentication state in local storage
            localStorage.setItem('authenticated', 'true');

            // Store the user data received from the Login component in local storage
            localStorage.setItem('user', JSON.stringify(userData));

            setUserData(userData);
        } else {
            // Remove authentication state and user data from local storage
            localStorage.removeItem('authenticated');
            localStorage.removeItem('user');
        }
    };

    const handleLogout = () => {
        // Clear user session and token from local storage
        localStorage.removeItem('user');
        localStorage.removeItem('oauth');

        // Call userHasAuthenticated to update authentication status
        userHasAuthenticated(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Header
                    isAuthenticated={authenticated}
                    username={userData.username}
                    handleLogout={handleLogout}
                />
                <div className='App'>
                    <Routes>
                        <Route
                            path='/'
                            element={authenticated ? <VoiceTranscriber/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />

                        <Route
                            path='/words'
                            element={authenticated ? <Words/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />

                        <Route
                            path='/transcribe'
                            element={authenticated ? <Transcribe/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />

                        <Route
                            path='/corpora'
                            element={authenticated ? <Corpora/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />

                        <Route
                            path='/audio'
                            element={authenticated ? <Audio/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />
                        <Route
                            path='/training'
                            element={authenticated ? <Training/> :
                                <Login userHasAuthenticated={userHasAuthenticated}/>}
                        />
                        <Route
                            path='/login'
                            element={<Login userHasAuthenticated={userHasAuthenticated}
                                            isAuthenticated={authenticated}/>}
                        />
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
