import React from 'react';
import PropTypes from 'prop-types';
import {Paper, List, ListItem, Typography, Box} from '@mui/material';

interface ISpeakerResult {
    speaker: number | null;
    alternatives: { transcript: string }[];

}

interface IMessage {
    result_index: number;
    results: ISpeakerResult[];
}

interface ISpeakersViewProps {
    messages: IMessage[];
    options?: { started: boolean }

}

export const SpeakersView: React.FC<ISpeakersViewProps> = ({messages, options}) => {
    let label
    options?.started && !messages.length && (label = 'Waiting for transcript...')
    !options?.started && !messages.length && (label = "Browse/start to see the transcript...")
    try {
        const results = messages.map((msg, index) =>
            msg.results.map((result, i) => {
                return <ListItem key={`result-${msg.result_index + i}`}>
                    <div className="model-transcript-entry">
           <span className='label' style={{fontWeight: "bold", marginRight: '10px'}}>
            {typeof result.speaker === 'number' ? `Speaker ${result.speaker + 1}: ` : 'Detecting speakers: '}
          </span> {result.alternatives[0].transcript}
                    </div>
                </ListItem>
            })
        ).reduce((a, b) => a.concat(b), []);

        return (
            <Paper elevation={2} sx={{height: 250, overflow: 'auto', mt: 5, p: 2}}>
                <List>{label || results}</List>

            </Paper>
        );
    } catch (ex: any) {
        console.error(ex);
        return <Typography color="error">{ex.message}</Typography>;
    }
};

SpeakersView.propTypes = {
    messages: PropTypes.array.isRequired,
};
