import io from 'socket.io-client';
import config from "./config";

export const socket = io(config.WS_ENDPOINT, {transports: ['websocket'], auth: {token: '!@#$%^&*'}});

export const joinRoom = (roomId: string) => {
    socket.emit('joinRoom', roomId)
}

export const leaveRoom = (roomId: string) => {
    socket.emit('leaveRoom', roomId)
}