import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    SelectChangeEvent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Grid, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './css/Transcribe.css';
import config from "../config";
import {authUser, getHeaders} from "../utils";
import {joinRoom, leaveRoom, socket} from "../socket";
import {PrettoSlider, SpeakersView, Transcript} from "../common";
import {Item} from "../common/base";

interface ModelData {
    name: string;
    status: string;
    customizationId: string;
    baseModelName: string;
    // Add any other properties here
}

type StreamSettings = {
    speakerLabels: boolean;
    // acousticModel?: string;
    // languageModel?: string
    baseModel?: string
    languageCustomizationId?: string
    backgroundAudioSuppression?: number
    speechDetectorSensitivity?: number
};

const languages = ['en-US_NarrowbandModel', 'en-US_Multimedia', 'en-US_Telephony', 'en-IN_Telephony', 'hi-IN_Telephony'];
const acoustics = ['en-US_NarrowbandModel'];
joinRoom(authUser().username)


export const Transcribe: React.FC = () => {
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [languageModel, setLanguageModel] = useState(languages[0]);
    const [acousticModel, setAcousticModel] = useState(acoustics[0]);
    const [languageModels, setLanguageModels] = useState<string[]>(languages);
    const [acousticModels, setAcousticModels] = useState<string[]>(acoustics);
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [transcriptions, setTranscription] = useState<any[]>([]);
    const [transcriptionResults, setTranscriptionResults] = useState<any[]>([]);
    const [languageModelData, setLanguageModelData] = useState<ModelData | null>(null);
    const [acousticModelData, setAcousticModelData] = useState<ModelData | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string>('');
    const [backgroundAudioSuppression, setBackgroundAudioSuppression] = useState(0.0); // Default sensitivity value
    const [sensitivity, setSensitivity] = useState(0.9); // Default sensitivity value
    const [streamSettings, setStreamSettings] = useState<StreamSettings>({
        speakerLabels: false,
        backgroundAudioSuppression: backgroundAudioSuppression,
        speechDetectorSensitivity: sensitivity
    });

    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [isRecording, setIsRecording] = useState(false); // New state for recording status


    useEffect(() => {
        handleSocket();
        getStatusLanguageModel();
        getStatusAcousticModel();
        setTranscriptionResults([]);

    }, []);

    useEffect(() => {
        const result = getFinalAndLatestInterimResult();
        setTranscription(result);
    }, [transcriptionResults]);

    useEffect(() => {
        handleTranscribe()
    }, [audioFile]);

    const resetAll = () => {
        setTranscriptionResults([])
        setIsTranscribing(false)
        setAudioFile(null)
    }

    const handleBackgroundAudioSuppressionChange = (event: Event, newValue: number | number[]) => {
        setBackgroundAudioSuppression(newValue as number);
        setStreamSettings({
            ...streamSettings,
            backgroundAudioSuppression: newValue as number,
        })
    };

    const handleSensitivityChange = (event: Event, newValue: number | number[]) => {
        setSensitivity(newValue as number);

        setStreamSettings({
            ...streamSettings,
            speechDetectorSensitivity: newValue as number,
        })

    };

    //_________________________________________
    const startRecording = async () => {
        setTranscriptionResults([]);
        setIsRecording(true)
        socket.emit('start-transcription', streamSettings);

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
            const options = {}
            // const options = {mimeType: 'audio/webm'}; // Use WAV format for compatibility with PCM
            const newMediaRecorder = new MediaRecorder(stream, options);
            newMediaRecorder.ondataavailable = async (event) => {

                if (event.data.size > 0) {
                    const audioBlob = event.data;
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const response = await fetch(audioUrl);
                    const audioBuffer = await response.arrayBuffer();
                    socket.emit('voice', audioBuffer);
                }
            };
            newMediaRecorder.start(250); // Increased timeslice for reduced network load
            setMediaRecorder(newMediaRecorder);
        } catch (error) {
            setIsRecording(false)
            console.error('Error starting recording:', error);
        }
    };


    const stopRecording = () => {
        setIsRecording(false)
        mediaRecorder?.stop();
        mediaRecorder?.stream.getTracks().forEach(track => track.stop());
        socket.emit('voice-end');
    };

    const handleRecordButtonClick = async () => {
        if (isRecording) {
            stopRecording();
        } else {
            await startRecording();
        }
    };

    //_________________________________________


    const getFinalResults = () => {
        return transcriptionResults.filter((r: any) => r.results && r.results.length && r.results[0].final).reduce((acc: any[], current: any) => {
            const x = acc.find(item => item.result_index === current.result_index);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    };

    const getCurrentInterimResult = () => {
        const r: any = transcriptionResults[transcriptionResults.length - 1];

        // When resultsBySpeaker is enabled, each msg.results array may contain multiple results.
        // However, all results in a given message will be either final or interim, so just checking
        // the first one still works here.
        if (!r || !r.results || !r.results.length || r.results[0].final) {
            return null;
        }
        return r;
    };

    const getFinalAndLatestInterimResult = () => {
        const final: any[] = getFinalResults();
        const interim: any = getCurrentInterimResult();

        if (interim) {
            final.push(interim);
        }
        return final;
    };

    const handleSpeakerLabelsChange = (event: any) => {
        setStreamSettings({
            ...streamSettings,
            speakerLabels: event.target.checked,
        });
    };

    const handleSocket = () => {
        socket.on('roomJoined', (message: string) => {
            console.log(message);
        });

        socket.on('transcribe-result', (payload: any) => {
            setTranscriptionResults((prevResults) => [...prevResults, payload]);
        });

        socket.on('transcribe-close', (payload: any) => {
            // leaveRoom(authUser().username)
            setSelectedFileName('');
            setAudioFile(null);
            setIsTranscribing(false); // Enable the button

        });

        socket.on('transcribe-error', (payload: any) => {
            // leaveRoom(authUser().username)
            setSelectedFileName('');
            setAudioFile(null);
            setIsTranscribing(false); // Enable the button
        });

    };

    const getStatusLanguageModel = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setLanguageModelData(data?.data);
            if (data?.data?.name) {
                setLanguageModels([...languages, data?.data?.name]);
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const getStatusAcousticModel = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/acoustic-model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setAcousticModelData(data?.data);
            if (data?.data?.name) {
                setAcousticModels([...acoustics, data?.data.name]);
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const handleLanguageModelChange = (event: SelectChangeEvent<string>) => {
        setLanguageModel(event.target.value);
        setStreamSettings({
            ...streamSettings,
            baseModel: event.target.value,
        })

        if (!languages.includes(event.target.value)) {
            setStreamSettings({
                ...streamSettings,
                baseModel: languageModelData?.baseModelName,
                languageCustomizationId: languageModelData?.customizationId
            });
        }
    };

    const handleAcousticModelChange = (event: SelectChangeEvent<string>) => {
        setAcousticModel(event.target.value);

    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            setSelectedFileName(file.name);
            setAudioFile(file);
        }
    };

    const handleTranscribe = (event?: any) => {
        // event.preventDefault();


        if (audioFile) {
            setTranscriptionResults([]);
            setIsTranscribing(true);

            const formData = new FormData();
            formData.append('audio', audioFile);
            formData.append('languageModel', languageModel);

            if (!languages.includes(languageModel)) {
                // @ts-ignore
                formData.append('baseModel', languageModelData.baseModelName);
                // @ts-ignore
                formData.append('languageCustomizationId', streamSettings.languageCustomizationId);

            } else {
                formData.append('baseModel', languageModel);

            }
            formData.append('acousticModel', acousticModel);
            formData.append('speakerLabels', JSON.stringify(streamSettings.speakerLabels));
            formData.append('backgroundAudioSuppression', JSON.stringify(streamSettings.backgroundAudioSuppression));
            formData.append('speechDetectorSensitivity', JSON.stringify(streamSettings.speechDetectorSensitivity));

            fetch(`${config.API_ENDPOINT}/stt/transcribe`, {
                method: 'POST',
                body: formData,
                headers: getHeaders(false)
            })
                .then((response) => {
                    response.json().then((data) => {
                        // joinRoom(authUser().username)
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setIsTranscribing(false);
                });
        }
    };

    return (
        <div className="transcribe">
            <Grid container spacing={2} alignItems={'center'} justifyContent={"center"}>
                <Grid item xs={6}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Speech Transcriber
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Convert audio to text using the customized models.
                        </Typography>

                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><b>Select models and audio file</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="language-model-label">Select Language Model</InputLabel>
                                            <Select
                                                labelId="language-model-label"
                                                value={languageModel}
                                                onChange={handleLanguageModelChange}
                                                label="Select Language Model"
                                            >
                                                {languageModels.map((model, index) => (
                                                    <MenuItem key={index} value={model}>{model}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel id="acoustic-model-label">Select Acoustic Model</InputLabel>*/}
                                    {/*        <Select*/}
                                    {/*            labelId="acoustic-model-label"*/}
                                    {/*            value={acousticModel}*/}
                                    {/*            onChange={handleAcousticModelChange}*/}
                                    {/*            label="Select Acoustic Model"*/}
                                    {/*        >*/}
                                    {/*            {acousticModels.map((model, index) => (*/}
                                    {/*                <MenuItem key={index} value={model}>{model}</MenuItem>*/}
                                    {/*            ))}*/}
                                    {/*        </Select>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}

                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={streamSettings.speakerLabels}
                                                                   onChange={handleSpeakerLabelsChange}
                                                />}
                                                label="Speaker Labels"/>
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <PrettoSlider
                                            valueLabelDisplay='auto'
                                            aria-label='speech-sensitivity-slider'
                                            value={sensitivity}
                                            step={0.1}
                                            min={0}
                                            max={1}
                                            disabled={isRecording || isTranscribing}
                                            onChange={handleSensitivityChange}
                                        />
                                        <Typography gutterBottom fontSize={12}>Speech Sensitivity</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PrettoSlider
                                            color='primary'
                                            valueLabelDisplay='auto'
                                            value={backgroundAudioSuppression}
                                            step={0.1}
                                            min={0}
                                            max={1}
                                            disabled={isRecording || isTranscribing}
                                            onChange={handleBackgroundAudioSuppressionChange}
                                        />
                                        <Typography gutterBottom fontSize={12}> Audio suppression</Typography>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Button
                                                size='small'
                                                variant="contained"
                                                component="label"
                                                disabled={isTranscribing}>
                                                {selectedFileName ? selectedFileName : 'Browse...'}
                                                <input
                                                    type="file"
                                                    hidden
                                                    accept=".wav,.mp3,.flac"
                                                    onChange={handleFileChange}
                                                />
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={4}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <Button*/}
                                    {/*            size='small'*/}
                                    {/*            disabled={isTranscribing}*/}
                                    {/*            variant="contained"*/}
                                    {/*            color="primary"*/}
                                    {/*            onClick={handleTranscribe}*/}
                                    {/*        >*/}
                                    {/*            Transcribe*/}
                                    {/*        </Button>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}

                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Button
                                                size='small'
                                                variant="contained"
                                                color={isRecording ? "error" : "primary"}
                                                onClick={handleRecordButtonClick}
                                                // disabled={true}
                                            >
                                                {isRecording ? 'Stop Recording' : 'Start Recording'}                                            </Button>
                                        </FormControl>

                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Stack mt={2}></Stack>

                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <Typography>Transcriptions will appear here...</Typography>
                        {streamSettings.speakerLabels
                            ?
                            <SpeakersView messages={transcriptions} options={{started: isTranscribing || isRecording}}/>
                            :
                            <Transcript messages={transcriptions} options={{started: isTranscribing || isRecording}}/>}

                    </Item>
                </Grid>

            </Grid>


        </div>
    );
};
