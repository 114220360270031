import React, {useState, useEffect} from 'react';
import {
    Button,
    Table,
    FormControl,
    Input,
    FormHelperText,
    CircularProgress,
    IconButton,
    Paper,
    Stack,

    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
} from '@mui/material';
import config from '../config';
import './css/Audio.css';
import {Delete} from "@mui/icons-material";
import {getHeaders} from "../utils";

interface ModelData {
    name: string;
    status: string;
    customizationId: string,
    baseModelName: string
    // Add any other properties here
}

const Audio = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [audio, setAudio] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [listError, setListError] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [filename, setFilename] = useState('');
    const [poolingInterval, setPoolingInterval] = useState<any>()
    const [acousticLanguageModel, setAcousticLanguageModel] = useState<ModelData | null>(null)

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');


    useEffect(() => {
        getAcousticLanguageModel()
        // handleGetList();
        // const interval = setInterval(pollAudio, 3000);
        // return () => clearInterval(interval);
    }, []);

    const handlePanelToggle = () => {
        setFileUploadOpen(!fileUploadOpen);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length) {
            const selectedFile = event.target.files[0];
            setFilename(selectedFile.name);
        }
    };


    const getAcousticLanguageModel = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/acoustic-model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();
            setAcousticLanguageModel(data?.data);

            if (data?.data?.customizationId) {
                await handleGetList(data?.data?.customizationId);
                // const interval = setInterval(async () => {
                //     pollAudio()
                // }, 20000);
                // setPoolingInterval(interval)
            }


        } catch (err: any) {
            setSnackbarMessage(err)
        }
    };


    const uploadAudio = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsUploading(true);
        setUploadError('');
        const formData = new FormData();
        const fileInput = event.currentTarget.querySelector('input[type="file"]') as HTMLInputElement;
        const selectedFile = fileInput.files && fileInput.files[0];
        if (selectedFile) {
            formData.append('audio', selectedFile);
            fetch(`${config.API_ENDPOINT}/stt/audio?acousticModelId=${acousticLanguageModel?.customizationId}`, {
                method: 'POST',
                body: formData,
                headers: {...getHeaders(false)}
            })
                .then(async (response) => {
                    response.json().then(async (data) => {
                        setFilename('');
                        acousticLanguageModel?.customizationId && await handleGetList(acousticLanguageModel.customizationId);
                        setIsUploading(false);
                    });
                })
                .catch((err) => {
                    setUploadError(`Could not add audio resource: ${err.message}`);
                    setIsUploading(false);
                });

        }
    };

    const checkAudioProcessing = () => {
        return audio.some((element: any) => element.status === 'being_processed');
    };

    const sortAudio = (audioArray: any) => {
        return [...audioArray].sort((a, b) => {
            if (a.status === b.status) {
                return a.name.localeCompare(b.name);
            }
            return a.status === 'ok' ? -1 : 1;
        });
    };

    const pollAudio = () => {
        fetch(`${config.API_ENDPOINT}/stt/audio`, {
            method: 'GET',
            headers: getHeaders()
        })
            .then((response) => {
                response.json().then((data) => {
                    const sortedAudio: any = sortAudio(data.data.audio);
                    setAudio(sortedAudio);
                    if (!checkAudioProcessing()) {
                        clearInterval(undefined);
                    }
                });
            })
            .catch((err: any) => {
                setListError(err.message);
                setIsLoading(false);
                clearInterval(undefined);
            });
    };

    const handleGetList = async (customizationId: string) => {
        setListError('');
        setIsLoading(true);
        fetch(`${config.API_ENDPOINT}/stt/audio?customizationId=${customizationId}`, {
            method: 'GET',
            headers: getHeaders()
        })
            .then((response) => {
                response.json().then((data) => {
                    const sortedAudio: any = sortAudio(data.data.audio);
                    setAudio(sortedAudio);
                    setIsLoading(false);
                });
            })
            .catch((err) => {
                setListError(err.message);
                setIsLoading(false);
            });
    };

    const handleDelete = (audioName: any) => {
        setIsDeleting(true);
        fetch(`${config.API_ENDPOINT}/stt/audio/`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({audioName, customizationId: acousticLanguageModel?.customizationId})
        })
            .then(async () => {
                acousticLanguageModel?.customizationId && (await handleGetList(acousticLanguageModel.customizationId));
                setIsDeleting(false);
            })
            .catch((err) => {
                setListError(err.message);
                setIsDeleting(false);
            });
    };

    return (
        <div className="Audio">
            <div style={{width: '70%'}}>

                <h2>Audio Resource List</h2>
                <p>
                    After a new audio resource has been added and processed, you must initialize
                    a <a href="/training" title="Train">training session</a> for the custom acoustic model
                    with the new data.
                </p>
                <Paper elevation={3}>

                    <form onSubmit={uploadAudio} style={{padding: '10px 20px'}}>
                        <FormControl fullWidth>
                            <Input
                                id="file"
                                type="file"
                                onChange={handleFileChange}
                                inputProps={{accept: 'audio/*, .zip, .tgz, .tar.gz'}}/>
                            <Stack mt={1}></Stack>

                            <FormHelperText> Accepted file types: .wav, .mp3, .flac, .zip, .tar.gz<br/>
                                Adding multiple audio files via a single archive file is significantly more
                                efficient than adding each file individually.</FormHelperText>
                        </FormControl>
                        <Stack mt={1}></Stack>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!filename}
                        >
                            {isUploading ? 'Uploading...' : 'Upload'}
                        </Button>
                        <Stack mt={2}></Stack>

                    </form>

                </Paper>

                <Stack mt={3}></Stack>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Duration (s)</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {audio.map((audioItem: any, index) => (
                                <TableRow key={index}>
                                    <TableCell>{audioItem.name}</TableCell>
                                    <TableCell>{audioItem.details?.type || '-'}</TableCell>
                                    <TableCell>{audioItem.duration || '-'}</TableCell>
                                    <TableCell>
                  <span className={audioItem.status === 'ok' ? 'text-success' : 'text-warning'}>
                    {audioItem.status}{' '}
                      {audioItem.status === 'being_processed' && <CircularProgress size={16}/>}
                  </span>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            disabled={isDeleting}
                                            aria-label="delete"
                                            onClick={() => {
                                                if (window.confirm('Delete this audio resource?')) {
                                                    handleDelete(audioItem.name);
                                                }
                                            }}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Audio;
