import React, {useEffect, useState} from 'react';
import {Button, FormControl, IconButton, InputAdornment, TextField} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import config from '../config';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';


interface LoginProps {
    isAuthenticated?: boolean;
    userHasAuthenticated: (isAuthenticated: boolean, userData?: any) => void;
}

export const Login = (props: LoginProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // If the user is already authenticated, redirect to the home page
        if (props.isAuthenticated) {
            navigate('/');
        }
    }, [props.isAuthenticated, navigate]);

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    };

    const handleChange = (prop: string) => (event: { target: { value: React.SetStateAction<string>; }; }) => {
        if (prop === 'username') {
            setUsername(event.target.value);
        } else if (prop === 'password') {
            setPassword(event.target.value);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        fetch(`${config.API_ENDPOINT}/oauth/login`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin':'*'
            },
        })
            .then((response: any) => {
                if (!response.ok) throw new Error(response.message);
                return response.json();
            })
            .then((data: any) => {
                handleLoginSuccess(data?.data);
            })
            .catch((err) => {
                console.log('error');
                setError(err.message);
                setSnackbarOpen(true);
            });
    };

    const handleLoginSuccess = (data: any) => {
        // Store user and token data in session
        localStorage.setItem('user', JSON.stringify(data?.user));
        localStorage.setItem('oauth', JSON.stringify(data?.oauth));

        // Call the parent component's function to update authentication status
        props.userHasAuthenticated(true, data?.user);

        // Navigate to the home page
        navigate('/'); // Use this to navigate programmatically
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setError(null);
    };

    return (
        <div className='Login' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center', // This will center the form vertically
            height: '100vh', // Take full viewport height
            width: '100vw', // Take full viewport width
        }}>
            <form onSubmit={handleSubmit} style={{width: '25rem'}}>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label='Username'
                        id='username'
                        type='text'
                        value={username}
                        onChange={handleChange('username')}
                        autoComplete='username'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                </InputAdornment>
                            ),
                        }}
                        variant='outlined'
                        autoFocus
                    />
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label='Password'
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleChange('password')}
                        autoComplete='current-password'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant='outlined'
                    />
                </FormControl>
                <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={!validateForm()}
                    sx={{mt: 3, mb: 2}}
                >
                    Log In
                </Button>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={10000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                >
                    <Alert
                        severity='error'
                        action={
                            <IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackbarClose}>
                                <CloseIcon fontSize='small'/>
                            </IconButton>
                        }
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </form>
        </div>
    );
};
