// Function to get headers for API requests
export const getHeaders = (json: boolean = true) => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem('authenticated') === 'true';

    // Initialize headers object
    const headers: any = {
        ...json && {'Content-Type': 'application/json'},
    };

    // Check if user is authenticated and has an OAuth token
    if (isAuthenticated) {
        const oauthData = localStorage.getItem('oauth');
        const oauth = oauthData ? JSON.parse(oauthData) : null;
        if (oauth && oauth?.oauthToken) {
            headers['Authorization'] = `Bearer ${oauth.oauthToken}`;
        }
    }

    return headers;
};


const JSON_HEADER = /application\/json/i;
export const handleFetchNonOK = async (response: any) => {
    if (!response.ok) {
        // Get error message from the 'error' property, if the payload is JSON
        if (JSON_HEADER.test(response.headers.get('Content-Type'))) {
            const json = await response.json();
            throw Error(json.error || response.statusText);
        }
        throw Error(response.statusText);
    }
    return response;
};


export const toCamelCase = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => toCamelCase(item));
    }

    return Object.entries(obj).reduce((acc: any, [key, value]) => {
        const camelCaseKey = key.replace(/_([a-zA-Z])/g, (match, letter) => letter.toUpperCase());
        acc[camelCaseKey] = toCamelCase(value);

        return acc;
    }, {});
};

export const toSnakeCase = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => toSnakeCase(item));
    }

    return Object.entries(obj).reduce((acc: any, [key, value]) => {
        const snakeCaseKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
        acc[snakeCaseKey] = toSnakeCase(value);

        return acc;
    }, {});
};


export const authUser = (): { username: string & any } => {
    const userDataFromStorage = localStorage.getItem('user');
    return userDataFromStorage ? JSON.parse(userDataFromStorage) : {};
};


export function convertToL16(audioBuffer: ArrayBuffer, sampleRate: number): Int16Array {
    const numSamples = audioBuffer.byteLength / 2; // Assuming 16-bit audio
    const l16Buffer = new Int16Array(numSamples);
    const dataView = new DataView(audioBuffer);
    let index = 0;

    for (let i = 0; i < numSamples; i++) {
        // Check if the index is within the bounds of the DataView
        if (index + 2 > audioBuffer.byteLength) {
            break; // Exit the loop if index is out of bounds
        }

        // Read the 16-bit audio sample from the DataView
         // Little-endian format
        l16Buffer[i] = dataView.getInt16(index, true);
        index += 2; // Move to the next 16-bit sample
    }

    return l16Buffer;
}
