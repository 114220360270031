import React, {useState, useEffect} from 'react';
import {
    Button,
    Table,
    FormControl,
    Input,
    FormHelperText,
    CircularProgress,

    Typography,
    Paper,
    Stack, AlertTitle, Alert, Snackbar, TableContainer, TableHead, TableCell, TableRow, TableBody
} from '@mui/material';
import {Delete, } from '@mui/icons-material';
import config from '../config';
import {getHeaders, handleFetchNonOK} from "../utils";
import './css/Corpora.css';

interface Corpus {
    name: string;
    outOfVocabularyWords: number;
    totalWords: number;
    status: string;
}

interface ModelData {
    name: string;
    status: string;
    customizationId: string,
    baseModelName: string
    // Add any other properties here
}

const Corpora: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [corpora, setCorpora] = useState<Corpus[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [fileUploadOpen, setFileUploadOpen] = useState<boolean>(true);
    const [filename, setFilename] = useState<string>('');
    const [languageModel, setLanguageModel] = useState<ModelData | null>(null)
    const [fileContents, setFileContents] = useState<string>('')
    const [poolingInterval, setPoolingInterval] = useState<any>()

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');


    useEffect(() => {
        getLanguageModel()
        // const interval = setInterval(pollCorpora, 5000);
        // setPoolingInterval(interval)
        // return () => clearInterval(poolingInterval);
    }, []);


    const handlePanelToggle = () => {
        setFileUploadOpen(true);
        // setFileUploadOpen(!fileUploadOpen);
    };


    const getLanguageModel = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();
            setLanguageModel(data?.data);

            if (data?.data?.customizationId) {
                await handleGetList(data.data.customizationId);
                const interval = setInterval(async () => {
                    pollCorpora(data.data.customizationId)
                }, 200000);
                setPoolingInterval(interval)
            }


        } catch (err: any) {
            setSnackbarMessage(err)
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                setFileContents(fileReader.result as string);
            };
            fileReader.readAsText(event.target.files[0]);
            setFilename(event.target.files[0].name);
        }
    };


    const uploadCorpora = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsUploading(true);
        fetch(`${config.API_ENDPOINT}/stt/corpora`, {
            method: 'POST',
            body: JSON.stringify({
                customizationId: languageModel?.customizationId,
                corpusName: filename,
                corpus: fileContents
            }),
            headers: getHeaders()
        })
            .then(handleFetchNonOK)
            .then((response) => {
                response.json().then((data: any) => {
                    setFileContents('')
                    handlePanelToggle();
                    setFilename('');
                    languageModel?.customizationId && handleGetList(languageModel?.customizationId);
                    setIsUploading(false);
                });
            })
            .catch((err) => {
                setSnackbarMessage(`Could not add corpus: ${err.message}`)
                setIsUploading(false);
            });
    };

    const checkCorporaProcessing = () => {
        return corpora.some(element => element.status === 'being_processed');
    };

    const sortCorpora = (corporaArray: Corpus[]) => {
        return [...corporaArray].sort((a, b) => {
            if (a.status === b.status) {
                return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
            }
            return (a.status > b.status) ? -1 : ((a.status < b.status) ? 1 : 0);
        });
    };

    const pollCorpora = (customizationId: string) => {
        fetch(`${config.API_ENDPOINT}/stt/corpora?customizationId=${customizationId}`, {
            method: 'GET',
            headers: getHeaders(),

        })
            .then((response) => {
                response.json().then((data: any) => {
                    if (data?.data.corpora && data.data.corpora.length) {
                        const sortedCorpora = sortCorpora(data.data.corpora);
                        setCorpora(sortedCorpora);
                        if (!checkCorporaProcessing()) {
                            clearInterval(poolingInterval);
                        }
                    }
                });
            })
            .catch((err) => {
                setSnackbarMessage(`${err.message}`)
                setIsLoading(false);
                clearInterval(poolingInterval);
            });


    };

    const handleGetList = async (customizationId: string) => {
        setIsLoading(true);

        fetch(`${config.API_ENDPOINT}/stt/corpora?customizationId=${customizationId}`, {
            headers: getHeaders(),
            method: 'GET',
        })
            .then((response) => {
                response.json().then((data: any) => {
                    const sortedCorpora = sortCorpora(data?.data.corpora);
                    setCorpora(sortedCorpora);
                    setIsLoading(false);
                });
            })
            .catch((err) => {
                setSnackbarMessage(`${err.message}`)
                setIsLoading(false);
            });


    };

    const handleDelete = async (corpusName: string) => {
        setIsDeleting(true);
        fetch(`${config.API_ENDPOINT}/stt/corpora/`, {
            headers: getHeaders(),
            body: JSON.stringify({customizationId: languageModel?.customizationId, corpusName}),
            method: 'DELETE',
        })
            .then(handleFetchNonOK)
            .then(() => {
                languageModel?.customizationId && handleGetList(languageModel.customizationId);
                setIsDeleting(false);
            })
            .catch((err) => {
                setSnackbarMessage(`${err.message}`)
                setIsDeleting(false);
            });
    };

    return (
        <div className="Corpora">
            <div style={{width: '70%'}}>
                <Typography variant="h4">Corpus List</Typography>
                <Stack mt={3}></Stack>

                <Typography>
                    After new corpora have been analyzed, you must initialize
                    a <a href="/training" title="Training">training session</a> for the custom language model
                    with the new data.
                </Typography>
                <Paper>
                    <Stack mt={2}></Stack>
                    <form onSubmit={uploadCorpora} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '21px',
                        textAlign: 'center'
                    }}>
                        < FormControl>
                            < Input id="file"
                                    type="file" onChange={handleFileChange}
                                    inputProps={{accept: '.txt'}}/>
                            <Stack mt={1}></Stack>
                        </FormControl>
                        <Stack mt={3}></Stack>
                        <Button style={{width: "30%"}} disabled={!filename} type="submit" variant="contained"
                                color="primary">
                            {isUploading ? <CircularProgress size={24}/> : 'Upload'}
                        </Button>
                        <Stack mt={3}></Stack>

                    </form>
                    <Stack mt={3}></Stack>
                    <FormHelperText> <Stack ml={3}></Stack>
                        Accepted file types: .txt</FormHelperText>
                    <Stack mt={3}></Stack>

                </Paper>
                <Stack mt={3}></Stack>

                {
                    isLoading && <CircularProgress/>
                }
                {
                    !isLoading && corpora.length <= 0 &&
                    <Typography variant="body1"><strong>No corpora added</strong></Typography>
                }
                <Stack mt={3}></Stack>

                {
                    !isLoading && corpora.length > 0 &&
                    <TableContainer component={Paper}>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Out of Vocab Words</TableCell>
                                    <TableCell>Total Words</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {corpora.map((corpus, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{corpus.name}</TableCell>
                                        <TableCell>{corpus.outOfVocabularyWords}</TableCell>
                                        <TableCell>{corpus.totalWords}</TableCell>
                                        <TableCell>
                  <span className={corpus.status === 'analyzed' ? 'text-success' : 'text-warning'}>
                    {corpus.status}{' '}
                      {corpus.status === 'being_processed' && <CircularProgress size={16}/>}
                  </span>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    if (window.confirm('Delete this corpus?')) {
                                                        handleDelete(corpus.name);
                                                    }
                                                }}
                                                disabled={isDeleting}
                                                startIcon={<Delete/>}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                }

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
        ;
};

export default Corpora;
