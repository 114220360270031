const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || 'localhost:3000';

export {WEBSOCKET_URL};


export default {
    // API_ENDPOINT: 'http://localhost:3006/api/v1',
    // WS_ENDPOINT: 'ws://localhost:3006/',
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1',
    WS_ENDPOINT: process.env.REACT_APP_WS_ENDPOINT || 'ws://localhost:3000/',
    MAX_AUDIO_SIZE: process.env.REACT_APP_MAX_AUDIO_SIZE || 15000000,
};


