// Header.tsx

import React from 'react';
import {AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Link as RouterLink, useNavigate} from 'react-router-dom';

interface HeaderProps {
    isAuthenticated: boolean;
    username: string;
    handleLogout: () => void;
}

export const Header: React.FC<HeaderProps> = ({isAuthenticated, username, handleLogout}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMobileMenuClick = (path: string) => {
        if (path === '/login' && !isAuthenticated) {
            // Redirect to login page only if not authenticated
            navigate(path);
        } else {
            // Close the menu for other links or if already authenticated
            navigate(path);
            handleClose();
        }
    };

    return (
        <AppBar position='sticky'>
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    sx={{display: {sm: 'none'}}}
                    onClick={handleMenu}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    variant='h6'
                    component='div'
                    sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
                >
                    {isAuthenticated ? `Welcome, ${username}` : 'Provider Note Training'}
                </Typography>
                <Box sx={{display: {xs: 'none', md: 'flex'}, flexGrow: 1, justifyContent: 'center'}}>
                    <MenuItem component={RouterLink} to='/'>Live Transcription</MenuItem>
                    <MenuItem component={RouterLink} to = '/transcribe'>Transcribe</MenuItem>
                    <MenuItem component={RouterLink} to='/words'>Words</MenuItem>
                    <MenuItem component={RouterLink} to='/corpora'>Corpora</MenuItem>
                    <MenuItem component={RouterLink} to='/audio'>Audio</MenuItem>
                    <MenuItem component={RouterLink} to='/training'>Training</MenuItem>
                    {isAuthenticated ? (
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    ) : (
                        <MenuItem onClick={() => handleMobileMenuClick('/login')}>Login</MenuItem>
                    )}
                </Box>
                {isAuthenticated && (
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Avatar alt='Provider Note' src='/static/images/avatar/2.jpg'/>
                    </Box>
                )}
                <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleMobileMenuClick('/')}>Live Transcription</MenuItem>

                    <MenuItem onClick={() => handleMobileMenuClick('/training')}>Training</MenuItem>
                    {isAuthenticated ? (
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    ) : (
                        <MenuItem onClick={() => handleMobileMenuClick('/login')}>Login</MenuItem>
                    )}
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
