import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from "@mui/material";

interface IMessage {
    result_index: number;
    results: Array<{
        alternatives: Array<{ transcript: string }>;
    }>;
}

interface ITranscriptProps {
    messages: IMessage[];
    options?: { started: boolean }
}

export const Transcript: React.FC<ITranscriptProps> = ({messages, options}) => {
    let label
    options?.started && !messages.length && (label = 'Waiting for transcript...')
    !options?.started && !messages.length && (label = "Browse/start to see the transcript...")
    try {
        const results = messages
            .map((msg) =>
                    msg.results.map((result, i) => {
                        return <span key={`result-${msg.result_index + i}`}>
            {result.alternatives[0].transcript}
          </span>
                    })
            )
            .reduce((a, b) => a.concat(b), []);


        return (
            <Paper elevation={2} sx={{height: 250, overflow: 'auto', mt: 5, p: 2}}>
                <div style={{textAlign: 'start'}}>{label || results}</div>
            </Paper>)
    } catch (ex: any) {
        console.error(ex);
        return <div>{ex.message}</div>;
    }
};

Transcript.propTypes = {
    messages: PropTypes.array.isRequired,
};

