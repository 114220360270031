import React, {useState, useEffect} from 'react';
import {
    FormGroup,
    Typography,
    IconButton,
    TextField,
    Button,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails, Stack
} from '@mui/material';
import {Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Send} from '@mui/icons-material';
import config from '../config';
import './css/Word.css';
import {getHeaders} from "../utils";

interface Word {
    displayAs: string;
    soundsLike: string[];
    count: number;
    source: string[];
    word: string;
}

interface ModelData {
    name: string;
    status: string;
    customizationId: string,
    baseModelName: string
    // Add any other properties here
}

export default function Words() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean[]>([]);

    const [words, setWords] = useState<Word[]>([]);
    const [languageModel, setLanguageModel] = useState<ModelData | null>(null)

    useEffect(() => {
        getLanguageModel();
    }, []);

    const handleGetList = async (customizationId: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/words?customizationId=${customizationId}`, {
                method: 'GET',
                headers: getHeaders()
            });
            const data = await response.json();
            const fetchedWords: Word[] = data?.data?.words || [];
            const sortedWords = fetchedWords.sort((a, b) => (a.word < b.word ? -1 : a.word > b.word ? 1 : 0));
            setWords(sortedWords);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };


    const getLanguageModel = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/stt/model`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();
            setLanguageModel(data?.data);

            if (data?.data?.customizationId) {
                await handleGetList(data.data.customizationId);
            }


        } catch (err: any) {
        }
    };

    const handleDelete = async (wordIndex: number) => {
        const word = words[wordIndex].word;
        setIsSubmitting(prevState => {
            const newState = [...prevState];
            newState[wordIndex] = false;
            return newState;
        });
        try {
            if (languageModel?.customizationId) {
                await fetch(`${config.API_ENDPOINT}/stt/words`, {
                    method: 'DELETE',
                    headers: getHeaders(),
                    body: JSON.stringify({
                        customizationId: languageModel?.customizationId,
                        wordName: word.replace(' ', '-')
                    })
                })
                await handleGetList(languageModel.customizationId);
            } else {
                throw new Error('Language model is missing!!')
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsSubmitting(prevState => {
                const newState = [...prevState];
                newState[wordIndex] = false;
                return newState;
            })
        }
    }


    const handleAdd = async (wordIndex: number) => {
        const newWord = words[wordIndex];
        setIsSubmitting(prevState => {
            const newState = [...prevState];
            newState[wordIndex] = true;
            return newState;
        });
        try {
            if (languageModel?.customizationId) {

                await fetch(`${config.API_ENDPOINT}/stt/words`, {
                    method: 'POST',
                    body: JSON.stringify({...newWord, customizationId: languageModel.customizationId}),
                    headers: getHeaders()
                });
                setIsLoading(true)
                await handleGetList(languageModel.customizationId);
            } else {
                throw new Error('Language model is missing!!')
            }
        } catch (err) {
            console.error('Error adding word:', err);
        } finally {
            setIsSubmitting(prevState => {
                const newState = [...prevState];
                newState[wordIndex] = false;
                return newState;
            });
        }
    };

    const handleSoundsLike = (wordIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedWords = [...words];
        updatedWords[wordIndex].soundsLike = event.target.value.split(',').slice(0, 5);
        setWords(updatedWords);
    };

    const submitWord = (wordIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        handleAdd(wordIndex);
    };

    return (
        <div className="Words">
            <div style={{width: '70%'}}>
                <Typography variant="h4">Custom Word List ({words.length})</Typography>
                <Stack mt={1}></Stack>

                <Typography variant="body1">
                    These are the out-of-vocabulary words extracted from all the submitted corpora.
                </Typography>

                <Stack mt={2}></Stack>

                {isLoading && <CircularProgress className="tableload"/>}
                {!isLoading && words.length <= 0 && (
                    <Typography variant="body1">
                        <br/>
                        <strong>No out-of-vocabulary words</strong>
                    </Typography>
                )}
                <Stack mt={1}></Stack>

                {!isLoading &&
                    words.length > 0 &&
                    words.map((word, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <div className="wordAndAction">
                                    <Typography variant='h6'>{word.word}</Typography>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (window.confirm('Delete this word?')) {
                                                handleDelete(index);
                                            }
                                        }}
                                    >
                                        <DeleteIcon/>

                                    </IconButton>

                                </div>

                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="soundslike">
                                    <Typography variant="body1">
                                        <strong>Sounds Like:</strong>
                                    </Typography>
                                    <Stack mt={2}></Stack>

                                    <form>
                                        <FormGroup>
                                            <TextField
                                                onChange={handleSoundsLike(index)}
                                                value={words[index].soundsLike.join(',')}
                                                multiline
                                                variant="outlined"
                                                label="Add up to five comma-separated 'sounds-like' strings."
                                            />
                                        </FormGroup>
                                        <Stack mt={2}></Stack>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting[index]}
                                            onClick={submitWord(index)}
                                        >
                                            {isSubmitting[index] && 'Submitting...'}
                                            {isSubmitting[index] ? <CircularProgress size={20}/> : 'Submit'}
                                        </Button>
                                    </form>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </div>
        </div>
    );
}
